<template>
  <div class="ma-4">
    <v-row class="mt-2">
      <v-col cols="12" lg="12" md="12" sm="12">
        <v-card elevation="4">
          <v-card-title>
            <h2 class="mt-1 primary--text">
              <v-icon class="primary--text" medium>mdi-car-emergency</v-icon>
              <span class="mt-2">Layanan Instalasi Gawat Darurat</span>
            </h2>
            <!-- <v-btn
              color="purple darken-2"
              dark
              class="ma-2"
              @click="dialog = true"
              >Alert Penunjang

              <v-badge value="0" color="red darken-2" overlap>
                <v-icon medium> mdi-bell-badge-outline </v-icon>
              </v-badge></v-btn
            > -->
          </v-card-title>
          <v-card-text>
            <!-- Button to trigger dialog -->

            <v-row>
              <v-col cols="12" md="4" lg="4" sm="4"
                ><v-btn
                  @click="muatulang()"
                  color="red"
                  dark
                  class="btn-block"
                  large
                  >KLIK UTK TAMPILKAN SEMUA PASIEN</v-btn
                ></v-col
              >

              <v-col cols="12" md="4" lg="4" sm="4">
                <v-text-field
                  v-model="search"
                  dense
                  label="Cari Nama Pasien"
                  class="ma-1"
                  outlined
                  @input="fetchData"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" lg="4" sm="4">
                <v-select
                  dense
                  class="ma-1"
                  @change="fetchData"
                  v-model="rbulan"
                  :items="rentangbulan"
                  label="Rentang Bulan"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search + ' ' + search2"
              :server-items-length="total"
              :loading="loading"
              :items-per-page="limit"
              height="750pt"
              fixed-header
              :disable-sort="true"
              @update:page="fetchData"
              @update:options="updatePagination"
            >
              <template #[`item.folio_id`]="{ item }">
                <v-btn
                  small
                  fab
                  dark
                  :loading="loading5"
                  :disabled="loading5"
                  color="primary white--text"
                  @click="goToProfilPasien(item)"
                >
                  <v-icon>mdi-pencil</v-icon> </v-btn
                >&nbsp;
              </template>
              <template #[`item.birth_date`]="{ item }">
                {{ item.birth_date | moment("DD MMMM YYYY") }}
              </template>

              <template #[`item.patient_name`]="{ item }">
                {{ item.salutation + item.patient_name }}
              </template>
              <template #[`item.registration_date`]="{ item }">
                {{ item.registration_date }}
                <br />
                <v-chip dark :color="item.warna">{{ item.elapsedTime }}</v-chip>
              </template>
              <template #[`item.registration_id`]="{ item }">
                <v-chip
                  medium
                  class="ma-1"
                  :color="setPerawatColor(item.isPerawatAda)"
                  label
                  text-color="white"
                >
                  <v-icon left small> mdi-label </v-icon>
                  <span v-html="item.is_perawat"></span>
                </v-chip>
                <v-chip
                  medium
                  class="ma-1"
                  :color="setDokterColor(item.isiDokterAda)"
                  label
                  text-color="white"
                >
                  <v-icon left small> mdi-label </v-icon>
                  <span v-html="item.is_dokter"></span>
                </v-chip>
              </template>
              <template #[`item.patient_name`]="{ item }">
                {{ item.salutation }}<b>{{ item.patient_name }}</b>
              </template>
              <template #[`item.folio_status`]="{ item }">
                <v-chip
                  small
                  class="ma-2"
                  :color="setColorStatus(item.folio_status)"
                  text-color="white"
                  label
                >
                  {{ item.folio_status }}
                </v-chip>
              </template>
              <template #[`item.statusPenunjang`]="{ item }">
                <div v-if="item.statusPenunjang.length !== 0">
                  <v-chip
                    v-for="(items, indexs) of item.statusPenunjang"
                    :key="indexs"
                    small
                    class="ma-1"
                    :color="setWarnaLabor(items.status)"
                    label
                    @click="liathasillabor(items.folio_lab, items.status)"
                    text-color="white"
                  >
                    <v-icon left small> mdi-label </v-icon>
                    <span
                      >{{ items.jenis }} -
                      <span v-html="setNamaLabor(items.status)"></span
                    ></span>
                  </v-chip>
                </div>
                <div v-else>Tidak Ada Order Penunjang</div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Notification Dialog -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Notifications</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(notification, index) in notifications"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title>{{ notification.title }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  notification.message
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center">
      <v-dialog v-model="showDialogPenunjang" max-width="1280px">
        <v-card>
          <v-card-title class="headline"> Hasil Penunjang </v-card-title>
          <v-card-text>
            <div id="hasil"></div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { baseUrl, successMsg, baseUrlV2, errorMsg } from '@/plugins/supports'
import Vue from 'vue'
import firebase from '../../../src/firebaseInit'

export default {
  data () {
    return {
      dialog: false,
      user: JSON.parse(localStorage.getItem('user')),
      showDialogPenunjang: false,
      rentangbulan: [
        '1 Minggu',
        '1 BULAN',
        '2 BULAN',
        '3 BULAN',
        '6 BULAN',
        '1 TAHUN'
      ],
      notifications: [
        { title: 'Notification 1', message: 'This is the first notification.' },
        {
          title: 'Notification 2',
          message: 'This is the second notification.'
        },
        { title: 'Notification 3', message: 'This is the third notification.' }
      ],
      headers: [
        // Define your table headers here
        { text: 'Aksi', value: 'folio_id', width: '80px', align: 'center' },
        {
          text: 'Status',
          value: 'folio_status',
          width: '100px',
          align: 'left'
        },
        {
          text: 'Tanggal Masuk',
          value: 'registration_date',
          width: '190px',
          align: 'center'
        },
        {
          text: 'Status Penunjang',
          value: 'statusPenunjang',
          width: '160px',
          align: 'left'
        },
        {
          text: 'Nama Pasien',
          value: 'patient_name',
          width: '210px',
          align: 'left'
        },

        {
          text: 'Status Periksa',
          value: 'registration_id',
          width: '130px',
          align: 'left'
        },
        {
          text: 'No.Rekam Medis',
          value: 'medrec_no',
          width: '150px',
          align: 'center'
        },

        {
          text: 'Tanggal Lahir',
          value: 'birth_date',
          width: '160px',
          align: 'left'
        },
        {
          text: 'Nama Dokter',
          value: 'profile',
          width: '190px',
          align: 'left'
        },

        {
          text: 'Cara Bayar',
          value: 'pay_by_name',
          width: '260px',
          align: 'left'
        },
        {
          text: 'Jenis Kelamin',
          value: 'sex_name',
          width: '160px',
          align: 'left'
        },
        {
          text: 'No.BPJS/Asuransi',
          value: 'member_card',
          width: '160px',
          align: 'left'
        }
      ],
      items: [],
      search: '',
      tglPelayanan: '',
      loading5: false,
      search2: '',
      total: 0,
      startTime: null,
      elapsedTime: '',
      intervalId: null,
      page: 1,
      limit: 15,
      rbulan: '',
      loading: false
    }
  },
  mounted () {
    this.fetchData()
    this.muatulang()
  },
  created () {
    this.rbulan = '1 Minggu'
    this.fetchData()
    this.checkPanggilan()
    // setInterval(() => {
    // }, 1000)
    this.jamSekarang()

    this.intervalId = setInterval(this.updateTimes, 1000)

    this.clearQueryAndNavigate()
  },
  beforeDestroy () {
    this.stopTimer() // Stop the timer when the component is destroyed
  },
  methods: {
    liathasillabor (RESULTS, STATUS) {
      // alert(RESULTS)
      if (STATUS === 'DIVALIDASI PP-PJ') {
        this.showDialogPenunjang = true
        // eslint-disable-next-line no-undef
        $.ajax({
          url: baseUrlV2 + 'rme/labor-view?folio_id=' + RESULTS,
          // eslint-disable-next-line no-undef
          type: 'GET',
          success: (result) => {
            // // console.log(result);
            // eslint-disable-next-line no-undef
            $('#hasil').html(result)
            // // eslint-disable-next-line no-undef
            // $('#mymodal2').modal({ show: true })
          }
        })
      }
    },
    setNamaLabor (RESULTS) {
      if (RESULTS === 'DIVALIDASI PP-PJ') {
        return 'HASIL SELESAI'
      } else {
        return RESULTS
      }
    },
    jamSekarang () {
      const today = new Date()
      const yyyy = today.getFullYear()
      let mm = today.getMonth() + 1 // Months start at 0!
      let dd = today.getDate()

      if (dd < 10) dd = '0' + dd
      if (mm < 10) mm = '0' + mm

      const formattedToday = dd + '/' + mm + '/' + yyyy
      const todays = yyyy + '-' + mm + '-' + dd
      this.tglSekarang = formattedToday
      this.tglPelayanan = todays
    },
    setColorStatus (RESULTS) {
      if (RESULTS === 'Reservasi') {
        return 'grey'
      }
      if (RESULTS === 'Buka') {
        return 'light-green lighten-1'
      }
      if (RESULTS === 'Tutup') {
        return 'pink darken-4'
      }
      if (RESULTS === 'Batal') {
        return 'red darken-3'
      }
    },
    setDokterColor (RESULTS) {
      if (RESULTS === 0) {
        return 'red darken-1'
      } else {
        return 'green darken-1'
      }
    },
    setPerawatColor (RESULTS) {
      if (RESULTS === 0) {
        return 'red darken-1'
      } else {
        return 'green darken-1'
      }
    },
    setnilai (RESULTS) {
      // return setInterval(() => {
      //   // this.calculateElapsedTime(RESULTS)
      // }, 1000)
    },
    setWarnaLabor (RESULTS) {
      if (RESULTS === 'DIORDER') {
      } else if (RESULTS === 'DI ACC') {
        return 'red darken-3'
      } else if (RESULTS === 'DIKERJAKAN') {
        return 'orange darken-3'
      } else if (RESULTS === 'DIVALIDASI PP-PJ') {
        return 'green darken-1'
      } else {
        return 'green darken-3'
      }
    },

    checkPanggilan () {
      // WATCHER______________________________________________

      // console.log(firebase)
      // EVENT INTERUPT FIREBASE
      // var authRef_interupsi = firebase.database().ref('interupt') // listen to array post
      var isNewLoad = true
      // eslint-disable-next-line camelcase
      var authRef_interupsi = firebase.database().ref('notifikasi_igd') // listen to array post

      // eslint-disable-next-line no-unused-vars
      var ininya = this
      // ininya.$router.push({
      //           name: 'data-patient'
      //           // params: { no_rm: v.medrec_no, folio_id: v.folio_id }
      //         })
      // console.log(ininya)
      // eslint-disable-next-line camelcase
      authRef_interupsi.on('value', function (snapshot_intrp) {
        if (isNewLoad === false) {
          // if (true) {
          if (snapshot_intrp.val() != null) {
            // eslint-disable-next-line no-unused-vars
            const value = snapshot_intrp.val()

            console.log(value)

            if (value.perintah === 'SET_NOTIFIKASI') {
              successMsg('HASIL PEMERIKSAAN LABOR SUDAH SIAP')
            }
            // console.log(value.employee_id + ' VALUE')
            // console.log(pegawai.employee_id + 'pegawai id')
            // if (pegawai.employee_id === value.employee_id) {
            //   ininya.trueDipanggil = true
            //   ininya.patient_name = value.patient_name
            //   ininya.no_rm = value.no_rm
            //   ininya.folio_id = value.folio_id
            //   ininya.reg_id = value.reg_id
            //   ininya.sub_unit_id = value.sub_unit_id
            //   ininya.getDataPasien(value.no_rm)

            //   setTimeout(() => {
            //     localStorage.removeItem('firebasePasien')
            //     localStorage.setItem('firebasePasien', JSON.stringify(value))
            //   }, 100)
            // }
          }
        } else {
          isNewLoad = false
          console.log('[ignore Interupt] First Load')
        }
        // if (i != 0) {
        //     console.log(snapshot_intrp.val());
        //     alert('wa')
        //     res.send(snapshot_intrp.val());
        // }
        // i++
      })
    },
    clearQueryAndNavigate () {
      // Get the current route path
      const currentPath = this.$route.path

      // Push to the route with an empty query to clear all query parameters
      // eslint-disable-next-line handle-callback-err
      this.$router.push({ path: currentPath }).catch((err) => {})

      // Alternatively, if you want to navigate to a specific route name without query
      // eslint-disable-next-line handle-callback-err
      this.$router.push({ name: 'FORMIGD', query: {} }).catch((err) => {})
    },
    goToProfilPasien (v) {
      this.$router.push({
        name: 'ModulIgd',
        // params: { no_rm: v.medrec_no, folio_id: v.folio_id, registration_id: v.registration_id }
        query: {
          no_rm: v.medrec_no,
          folio_id: v.folio_id,
          registration_id: v.registration_id,
          sub_unit_id: v.sub_unit_id,
          type: 'igd'
        }
      })
    },
    muatulang () {
      this.loading = true
      const url =
        baseUrl +
        `igd/fetch-data?page=${this.page}&limit=${this.limit}&search=${this.search}&tgl=${this.tglPelayanan}&rentang=${this.rbulan}&id_pegawai=${this.user.employee_id}`
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          this.items = data.items
          this.total = data.total
          this.loading = false
          this.updateTimes()
          this.startTimer() // Start the timer for periodic updates
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
          this.loading = false
        })
    },
    fetchData () {
      // console.log(this.search2)

      // if (this.search.length >= 3) {
      this.loading = true
      const url =
          baseUrl +
          `igd/fetch-data?page=${this.page}&limit=${this.limit}&search=${this.search}&tgl=${this.tglPelayanan}&rentang=${this.rbulan}&id_pegawai=${this.user.employee_id}`
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          this.items = data.items
          this.total = data.total
          this.loading = false
          this.updateTimes()
          this.startTimer() // Start the timer for periodic updates
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
          this.loading = false
        })
      // } else {
      //   errorMsg('PENCARIAN HARUS LEBIH DARI 2 KATA')
      // }
    },
    updateTimes () {
      this.items.forEach((item, index) => {
        // Use Vue.set to ensure reactivity
        if (
          item.folio_status !== 'Tutup' &&
          item.folio_status !== 'Batal' &&
          item.warna !== 'green darken-3'
        ) {
          const elapsedTime =
            'Waktu Tunggu : ' +
            this.calculateElapsedTime(item.registration_date)
          Vue.set(this.items, index, { ...item, elapsedTime })
        } else {
          const elapsedTime =
            item.warna === 'green darken-3'
              ? 'PINDAH RAWAT INAP'
              : item.folio_status
          Vue.set(this.items, index, { ...item, elapsedTime })
        }

        // console.log(`Updated time for ${item.registration_date}: ${elapsedTime}`)
      })
    },
    calculateElapsedTime (registrationDate) {
      const parsedDate = this.parseDate(registrationDate)
      const now = new Date()
      const diff = Math.floor((now - parsedDate) / 1000)

      const hours = Math.floor(diff / 3600)
      const minutes = Math.floor((diff % 3600) / 60)
      const seconds = diff % 60

      return `${hours}h ${minutes}m ${seconds}s`
    },
    startTimer () {
      // Clear any existing timer
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }
      // Set up a new timer to update times every second
      this.intervalId = setInterval(() => {
        this.updateTimes()
      }, 1000)
    },
    stopTimer () {
      if (this.intervalId) {
        clearInterval(this.intervalId)
        this.intervalId = null
      }
    },
    updatePagination (options) {
      this.limit = options.itemsPerPage
      this.page = options.page
      this.fetchData()
    },
    parseDate (dateString) {
      const [datePart, timePart] = dateString.split(' ')
      const [day, month, year] = datePart.split('-').map(Number)
      const [hours, minutes, seconds] = timePart.split(':').map(Number)

      return new Date(year, month - 1, day, hours, minutes, seconds)
    }
  }
}
</script>
